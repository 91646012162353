import classNames from "classnames";
import { forEach, map } from "lodash";
import { FC, cloneElement, useCallback, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { CancelButton, DeleteButton, EditButton, SubmitButton, UpdateButton } from "../../../components/button";
import { AdjustableCard, CreateCard, Error, GraphBasedExpandableCard } from "../../../components/card";
import { ClassNames } from "../../../components/classes";
import { IDropdownItem } from "../../../components/dropdown";
import { Form, IFormVariable, useFormHook } from "../../../components/form";
import { IGraphCardProps } from "../../../components/graph/graph";
import { Pill } from "../../../components/pill";
import { Tooltip } from "../../../components/tooltip";
import { GraphElements } from "../../../config/constants";
import { Icons } from "../../../config/icons";
import { InternalRoutes } from "../../../config/internal-routes";
import { Cluster, GetClusterDocument, GetClustersDocument, GetDigitalOceanKubernetesClustersDocument, GetGoogleKubernetesClustersDocument, GetGoogleProjectsDocument, GetIntegrationsDocument, useCreateClusterMutation, useDeleteClusterMutation, useUpdateClusterMutation } from "../../../generated/graphql";
import { notify } from "../../../store/function";
import { IDataTransform } from "../../dashboard/context";
import { AddDashboardCard, IAddDashboardCardProps } from "../../dashboard/dashboard-card";
import { GraphCardLoader } from "../../dashboard/graph-card";
import { CreateIntegrationsCard } from "../../integrations/integrations-card";
import { IRefetch } from "../../../api/query";

export const DEPLOY_CLUSTER_ICON = {
    component: Icons.Deploy.Cluster.Default,
    bgClassName: "bg-sky-500",
}

export enum ClusterType {
    DigitalOcean="DigitalOcean",
    Google="Google",
}

function getDefaultForm(cluster?: Cluster): IFormVariable[] {
    return [
        {
            name: "name",
            label: "Name",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Name is required",
            defaultValue: cluster?.Name,
        },
        {
            name: "integrationId",
            label: "Integration",
            type: "query",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Integration is required",
            query: GetIntegrationsDocument,
            transform(data) {
                const dropdownItems: IDropdownItem[] = [];
                forEach(data?.Integration?.DigitalOceanIntegration ?? [], ({ Id, Name }) => {
                    dropdownItems.push({
                        id: Id,
                        label: Name,
                        icon: Icons.Logos.DigitalOcean,
                        extra: {
                            type: ClusterType.DigitalOcean,
                        }
                    });
                });
                forEach(data?.Integration?.GoogleIntegration ?? [], ({ Id, Name }) => {
                    dropdownItems.push({
                        id: Id,
                        label: Name,
                        icon: Icons.Logos.Google,
                        extra: {
                            type: ClusterType.Google,
                        }
                    });
                });
                return dropdownItems;
            },
            defaultIcon: Icons.Integrations,
            onClick(item, setForm) {
                setForm(f => {
                    f.type = item.extra.type;
                });
            },
            dropdownProps: {
                defaultItem: { icon: Icons.Add, label: "Create an integration" },
            },
            createNode: CreateIntegrationsCard,
            defaultValue: getClusterIntegrationId(cluster),
            disabled: getClusterIntegrationId(cluster) != null,
        },
        {
            name: "integrationClusterId",
            label: "Available clusters",
            type: "query",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Integration cluster is required",
            query: GetDigitalOceanKubernetesClustersDocument,
            transform(data) {
                return map(data?.DigitalOceanKubernetesClusters ?? [], ({ Id, Name }) => ({
                    id: Id,
                    label: Name,
                    icon: Icons.Deploy.Cluster.Default,
                }));
            },
            isLazy: true,
            shouldQuery(form) {
                if (form.integrationId.length === 0 || form.type == null) {
                    return [false, {}];
                }
                return [true, {
                    id: form.integrationId,
                }];
            },
            defaultIcon: Icons.Deploy.Cluster.Default,
            defaultValue: cluster?.IntegrationClusterId,
            dropdownProps: {
                defaultItem: {
                    label: "Create a digital ocean cluster",
                    icon: Icons.Add,
                },
                onDefaultItemClick() {
                    window.open("https://cloud.digitalocean.com/kubernetes/clusters/new", "_blank");
                },
            },
            disabled: cluster?.DigitalOcean?.Id != null ? true : (form) => form.integrationId === "",
            hide(form) {
                return form.type !== ClusterType.DigitalOcean;
            },
        },
        {
            name: "googleProjectId",
            label: "Available projects",
            type: "query",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Project id is required",
            query: GetGoogleProjectsDocument,
            transform(data) {
                return map(data?.GoogleProjects ?? [], ({ Id, Name }) => ({
                    id: Id,
                    label: Name,
                    icon: Icons.Deploy.Cluster.Default,
                }));
            },
            isLazy: true,
            shouldQuery(form) {
                if (form.integrationId.length === 0 || form.type == null) {
                    return [false, {}];
                }
                return [true, {
                    id: form.integrationId,
                }];
            },
            defaultIcon: Icons.Deploy.Cluster.Default,
            defaultValue: cluster?.IntegrationClusterData?.Google?.ProjectId,
            dropdownProps: {
                defaultItem: {
                    label: "Create a Google Cloud Project",
                    icon: Icons.Add,
                },
                onDefaultItemClick() {
                    window.open("https://console.cloud.google.com/projectcreate", "_blank");
                },
            },
            disabled: cluster?.Google?.Id != null ? true : (form) => form.integrationId === "",
            hide(form) {
                return form.type !== ClusterType.Google;
            },
        },
        {
            name: "integrationClusterId",
            label: "Available clusters",
            type: "query",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Integration cluster is required",
            query: GetGoogleKubernetesClustersDocument,
            transform(data) {
                return map(data?.GoogleKubernetesClusters ?? [], ({ Id, Name }) => ({
                    id: Id,
                    label: Name,
                    icon: Icons.Deploy.Cluster.Default,
                }));
            },
            isLazy: true,
            shouldQuery(form) {
                if (form.integrationId.length === 0 || form.type == null || form.googleProjectId.length === 0) {
                    return [false, {}];
                }
                return [true, {
                    id: form.integrationId,
                    projectId: form.googleProjectId,
                }];
            },
            defaultIcon: Icons.Deploy.Cluster.Default,
            defaultValue: cluster?.IntegrationClusterId,
            dropdownProps: {
                defaultItem: {
                    label: "Create a Google Kubernetes cluster",
                    icon: Icons.Add,
                },
                onDefaultItemClick() {
                    window.open("https://console.cloud.google.com/kubernetes/auto/add", "_blank");
                },
            },
            disabled: cluster?.Google?.Id != null ? true : (form) => form.integrationId === "",
            hide(form) {
                return form.type !== ClusterType.Google;
            },
        },
    ]
}

type ICreateClusterCardProps = {
    isEmbedded?: boolean;
    isCreating?: boolean;
    refetch: IRefetch;
}

export const CreateClusterCard: FC<ICreateClusterCardProps> = (props) => {
    const [error, setError] = useState("");
    const [createCluster, { loading }] = useCreateClusterMutation();
    const ref = useRef<Function>();
    const [formProps, { isFormValid, getForm }] = useFormHook();

    const handleSubmit = useCallback(() => {
        const validState = isFormValid();
        if (!validState.isValid) {
            return setError(validState.errorMessage);
        }
        setError("");
        const form = getForm();
        createCluster({
            variables: {
                name: form.name,
                type: form.type,
                integrationId: form.integrationId,
                integrationClusterId: form.integrationClusterId,
                googleClusterData: {
                    ProjectId: form.googleProjectId,
                },
            },
            onCompleted: (data) => {
                notify("Cluster created successfully", "success");
                ref.current?.();
                props.refetch(data.CreateCluster.Id);
            },
            onError: () => {
                notify("Unable to create cluster", "error");
            }
        });
    }, [isFormValid, getForm, createCluster, props]);

    const handleClose = useCallback(() => {
        setError("");
        ref.current?.();
    }, [ref]);

    return (
        <>
            <CreateCard isExpanded={props.isCreating || props.isEmbedded} label="Cluster" icon={{
                component: Icons.Add,
                bgClassName: DEPLOY_CLUSTER_ICON.bgClassName,
            }} setToggleCallback={(toggle) => ref.current = toggle}
                tag={<div className="flex flex-col gap-1 items-end">
                    <Tooltip tooltip={<div className="text-sm">Clidey will install a few things on your cluster. This could affect pricing.</div>}>
                        {cloneElement(Icons.ExclamationTriangle, {
                            className: "w-6 h-6 stroke-orange-800 dark:stroke-orange-500",
                        })}
                    </Tooltip>
                    <Error error={error} />
                </div>} loading={loading} loadingText="Creating cluster. This can take a few minutes.">
                <div className="flex flex-col gap-1 my-4 grow">
                    <Form variables={getDefaultForm()} {...formProps} />
                </div>
                <div className="flex justify-end items-center gap-2">
                    {!props.isEmbedded && <CancelButton onClick={handleClose} />}
                    <SubmitButton loading={loading} onClick={handleSubmit} />
                </div>
            </CreateCard>
        </>
    )
}

export const ClusterIntro: FC<{ cluster: Cluster }> = (props) => {
    return <div className="flex flex-col grow mt-2">
        <div className={classNames(ClassNames.Text, "text-md")}>
            {props.cluster.Name}
        </div>
        <div className="text-xs mt-2 flex flex-wrap gap-1">
            <Pill id={props.cluster.Id} label={props.cluster.IntegrationClusterName} ellipses={true} />
        </div>
    </div>
}

function getClusterIntegrationId(cluster?: Cluster) {
    if (cluster == null) {
        return null;
    }
    switch(cluster.Type) {
        case ClusterType.Google:
            return cluster.Google?.Id;
        case ClusterType.DigitalOcean:
            return cluster.DigitalOcean?.Id;
    }
}

type IClusterCardProps = {
    cluster: Cluster;
    refetch: IRefetch;
}

export const ClusterCard: FC<IClusterCardProps> = (props) => {
    const [error, setError] = useState("");
    const [deleteCluster, { loading: deleteLoading }] = useDeleteClusterMutation();
    const [updateCluster, { loading: updateLoading }] = useUpdateClusterMutation();
    const [formProps, { isFormValid, getForm }] = useFormHook({
        type: props.cluster.Type,
        integrationId: getClusterIntegrationId(props.cluster),
        googleProjectId: props.cluster.IntegrationClusterData?.Google?.ProjectId,
    });
    const toggleCardExpansion = useRef<Function>();
    const [searchParams, ] = useSearchParams();

    const handleCancel = useCallback(() => {
        toggleCardExpansion.current?.();
        setError("");
    }, [toggleCardExpansion]);

    const handleSubmit = useCallback(() => {
        const validState = isFormValid();
        if (!validState.isValid) {
            return setError(validState.errorMessage);
        }
        const form = getForm();
        setError("");
        updateCluster({
            variables: {
                id: props.cluster.Id,
                name: form.name,
            },
            onCompleted: (data) => {
                notify("Cluster updated successfully", "success");
                props.refetch(data.UpdateCluster.Id);
                toggleCardExpansion.current?.();
            },
            onError: () => {
                notify("Unable to update cluster", "error");
            }
        });
    }, [isFormValid, getForm, updateCluster, props]);

    const handleDelete = useCallback(() => {
        deleteCluster({
            variables: {
                id: props.cluster.Id,
            },
            onCompleted: () => {
                notify("Cluster deleted successfully!", "success");
                props.refetch();
            },
            onError: () => {
                notify("Unable to delete cluster", "error");
            },
        });
    }, [props, deleteCluster]);


    return <GraphBasedExpandableCard id={props.cluster.Id} type={GraphElements.Cluster} icon={DEPLOY_CLUSTER_ICON} setToggleCallback={(toggle) => toggleCardExpansion.current = toggle}
        tag={<Error error={error} />} highlight={searchParams.get("id") === props.cluster.Id} loading={deleteLoading || updateLoading}>
        <>
            <div className="flex flex-col grow">
                <div className={classNames(ClassNames.Text, "text-md mt-2")}>
                    {props.cluster.Name}
                </div>
            </div>
            <div className="flex">
                <EditButton onClick={() => toggleCardExpansion.current?.(true)} />
            </div>
        </>
        <>
            <div className="flex flex-col gap-1 my-4 grow">
                <Form variables={getDefaultForm(props.cluster)} defaultExtraValues={getForm()} {...formProps} />
            </div>
            <div className="flex justify-between items-center">
                <DeleteButton name={props.cluster.Name} loading={deleteLoading} onClick={handleDelete} />
                <div className="flex items-center gap-2">
                    <CancelButton onClick={handleCancel} />
                    <UpdateButton loading={updateLoading} onClick={handleSubmit} />
                </div>
            </div>
        </>
    </GraphBasedExpandableCard>
}

export const ClusterDashboardCard: FC<{ cluster: Cluster }> = (props) => {
    return <AdjustableCard showTools={true} icon={DEPLOY_CLUSTER_ICON}>
        <ClusterIntro cluster={props.cluster} />
    </AdjustableCard>
}

export const transformClusterData: IDataTransform<Cluster>  = (data?: { Cluster: Cluster[]}) => data?.Cluster ?? [];

export const ClusterGraphCard: FC<IGraphCardProps<Cluster>> = (props) => {
    return <GraphCardLoader loader={GetClusterDocument} transform={data => transformClusterData(data)[0]} {...props}>
        {data => (<ClusterDashboardCard cluster={data} />)}
    </GraphCardLoader>;
}

export const AddClusterDashboardCard: FC<IAddDashboardCardProps> = (props) => {
    return (<AddDashboardCard {...props} label="Cluster"
        query={GetClustersDocument}
        transform={transformClusterData} type={GraphElements.Cluster}
        icon={Icons.Deploy.Cluster.Default}
        link={InternalRoutes.Deployment.CreatCluster.path} />
    );
}