import classNames from "classnames";
import { FC, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactFlowProvider, useEdgesState, useNodesState } from "reactflow";
import { useImmer } from "use-immer";
import { v4 } from "uuid";
import { IRefetch } from "../../../api/query";
import { CreateButton } from "../../../components/button";
import { Error } from "../../../components/card";
import { ClassNames } from "../../../components/classes";
import { Page } from "../../../components/container";
import { Form, IFormGrid, useFormHook } from "../../../components/form";
import { createNode } from "../../../components/graph/utils";
import { Icons } from "../../../config/icons";
import { InternalRoutes } from "../../../config/internal-routes";
import { CreateFlowStep, Flow, useCreateFlowMutation } from "../../../generated/graphql";
import { notify } from "../../../store/function";
import { isNumeric } from "../../../utils/functions";
import { cpuConfigDropdownItems, memoryConfigDropdownItems } from "../../deploy/quick-container/quick-container-card";
import { FlowGraphViewContext, IFlowGraphData, IFlowGraphViewCache } from "./context";
import { GraphFlowCard } from "./explore-flow";
import { FlowElements } from "./flow-step-card";


export function getDefaultForm(flow?: Flow): IFormGrid {
    return [
        {
            name: "name",
            label: "Name",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Name is required",
            className: "min-w-[350px]",
            defaultValue: flow?.Name,
        },
        {
            name: "cpu",
            label: "CPU",
            type: "dropdown",
            fieldType: "text",
            validate: (cpu: string) => cpu.length > 0 && isNumeric(cpu),
            defaultIcon: Icons.Chip,
            errorMessage: (cpu: string) => {
                if (cpu.length === 0) {
                    return "CPU is required";
                }
                return "CPU has to be a valid number";
            },
            dropdownProps: {
                items: cpuConfigDropdownItems,
            },
            defaultValue: flow?.Resource.LimitsCPU?.toString() ?? "100",
        },
        {
            name: "memory",
            label: "Memory",
            type: "dropdown",
            fieldType: "text",
            validate: (memory: string) => memory.length > 0 && isNumeric(memory),
            defaultIcon: Icons.Chip,
            errorMessage: (memory: string) => {
                if (memory.length === 0) {
                    return "Memory is required";
                }
                return "Memory has to be a valid number";
            },
            dropdownProps: {
                items: memoryConfigDropdownItems,
            },
            defaultValue: flow?.Resource.LimitsMemory?.toString() ?? "100",
        },
    ]
}

type ICreateFlowPageProps = {
    isEmbedded?: boolean;
    refetch?: IRefetch;
}

export const CreateFlowPage: FC<ICreateFlowPageProps> = ({ isEmbedded, refetch }) => {
    const [createFlow, { loading }] = useCreateFlowMutation();
    const [cache, setCache] = useImmer<IFlowGraphViewCache>({});
    const [nodes, setNodes, onNodesChange] = useNodesState([
        createNode({
            id: v4(),
            type: FlowElements.Starting,
            data: undefined,
            deletable: false,
        })
    ]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const navigate = useNavigate();
    const [error, setError] = useState<string>();
    const [formProps, { isFormValid, getForm }] = useFormHook();

    const handleSubmit = useCallback(() => {
        const state = isFormValid();
        if (!state.isValid) {
            return setError(state.errorMessage);
        }
        if (nodes.length === 1 && nodes[0].type === FlowElements.Starting) {
            return setError("At least one step is required");
        }
        // todo:
        // if (!nodes.every(node => cache[node.id].isFormValid())) {
        //     return setError("All steps need to be valid");
        // }
        setError(undefined);
        const form = getForm();
        const steps: CreateFlowStep[] = nodes.map(nd => cache[nd.id].getForm());
        createFlow({
            variables: {
                name: form.name,
                steps,
                resource: {
                    LimitsCPU: form.cpu,
                    LimitsMemory: form.memory,
                    RequestsCPU: form.cpu,
                    RequestsMemory: form.memory,
                },
            },
            onCompleted: (data) => {
                notify("Flow created successfully", "success");
                if (!isEmbedded) {
                    navigate(InternalRoutes.CI_CD.Flow.path, {
                        state: {
                            refetch: true,
                        },
                    });
                }
                refetch?.(data.CreateFlow.Id);
            },
            onError: () => {
                notify("Unable to create flow", "error");
            },
        });
    }, [isFormValid, nodes, getForm, createFlow, cache, isEmbedded, refetch, navigate]);

    const handleSetCacheItem = useCallback((key: string, value: IFlowGraphData) => {
        setCache(c => {
            c[key] = value;
        });
    }, [setCache]);

    const handleRemoveCacheItem = useCallback((key: string) => {
        setCache(c => {
            delete c[key];
        });
    }, [setCache]);

    return <Page routes={[InternalRoutes.CI_CD.Flow, InternalRoutes.CI_CD.CreateFlow]}>
        <div className="flex flex-col gap-8 w-full h-full">
            <FlowGraphViewContext.Provider value={{
                cache,
                setCacheItem: handleSetCacheItem,
                removeCacheItem: handleRemoveCacheItem,
            }}>
                <div className="flex justify-between items-center">
                    <div className="flex items-center gap-4">
                        <div className={classNames(ClassNames.Text, "text-3xl")}>
                            Create New Flow
                        </div>
                    </div>
                </div>
                <div className="flex gap-8 h-[80vh]">
                    <div className="flex flex-col gap-2 w-full">
                        <div className="flex items-center gap-4">
                            <div className="flex w-1/2">
                                <Form direction="row" variables={getDefaultForm()} defaultExtraValues={getDefaultForm()} {...formProps} />
                            </div>
                            <Error error={error} />
                            <div className="flex grow items-center justify-end">
                                <CreateButton onClick={handleSubmit} loading={loading} />
                            </div>
                        </div>
                        <FlowGraphViewContext.Provider value={{
                            cache,
                            setCacheItem: handleSetCacheItem,
                            removeCacheItem: handleRemoveCacheItem,
                        }}>
                                <ReactFlowProvider>
                                    <GraphFlowCard nodes={nodes} setNodes={setNodes} onNodesChange={onNodesChange}
                                        edges={edges} setEdges={setEdges} onEdgesChange={onEdgesChange} />
                                </ReactFlowProvider>
                        </FlowGraphViewContext.Provider>
                    </div>
                </div>
            </FlowGraphViewContext.Provider>
        </div>
    </Page>
}