import { filter, map } from "lodash"
import { FC, useContext, useEffect, useMemo } from "react"
import { Node, useEdges, useNodes, useReactFlow } from "reactflow"
import { ICodeEditorSuggestion } from "../../../../components/editor"
import { Form, IForm, IFormVariable, useFormHook } from "../../../../components/form"
import { IGraphCardProps } from "../../../../components/graph/graph"
import { Icons } from "../../../../config/icons"
import { CreateGitReleaseHookStep, GetFlowsDocument, GitReleaseHookStep, HookStepType } from "../../../../generated/graphql"
import { getAllReferencesRecursively } from "../../../../utils/functions"
import { HookGraphViewContext } from "../context"
import { HooksStepCard } from "../hooks-step-card"
import { ICreateFlowOperationHookStep } from "./flow-operation-hook-step-card"
import { HandlebarCodeEditor, useGetHandlebarSuggestions } from "./handlebar-code-editor"


function getDefaultForm(suggestions: ICodeEditorSuggestion[], flowOperations?: ICreateFlowOperationHookStep[], gitReleaseHookStep?: GitReleaseHookStep, disabled?: boolean): IFormVariable[] {
    return [
        {
            name: "flowId",
            label: "Image tag for release",
            type: "query",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Quick container is required",
            query: GetFlowsDocument,
            transform(data) {
                return filter(map(data?.Flow ?? [], ({ Id, Name }) => ({
                    id: Id,
                    label: Name,
                    icon: Icons.CI_CD.Flow.Default,
                })), item => flowOperations?.find(operation => operation.FlowOperation?.FlowId === item.id) != null);
            },
            defaultValue: gitReleaseHookStep?.Flow?.Id,
            disabled,
        },
        {
            name: "includePRInformation",
            label: "Include PR Information",
            fieldType: "bool",
            validate: () => true,
            errorMessage: "",
            defaultValue: gitReleaseHookStep?.IncludePRInformation ?? false,
        },
        {
            name: "generateReleaseNotes",
            label: "Generate Release Notes",
            fieldType: "bool",
            validate: () => true,
            errorMessage: "",
            defaultValue: gitReleaseHookStep?.GenerateReleaseNotes ?? false,
        },
        {
            name: "isDraft",
            label: "Draft",
            fieldType: "bool",
            validate: () => true,
            errorMessage: "",
            defaultValue: gitReleaseHookStep?.IsDraft ?? false,
        },
        {
            name: "message",
            label: "Message",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Message is required",
            defaultValue: gitReleaseHookStep?.Message,
            onRender: (value: string, setValue: (content: string) => void) => {
                return <HandlebarCodeEditor value={value} setValue={setValue} suggestions={suggestions} />
            },
        },
    ]
}


export type ICreateGitReleaseHookStep = {
    Type: HookStepType.GitRelease;
    GitRelease: CreateGitReleaseHookStep;
}

export const GitReleaseHookStepCard: FC<IGraphCardProps<GitReleaseHookStep>> = (props) => {
    const { cache, setCacheItem } = useContext(HookGraphViewContext);
    const { getNode } = useReactFlow();
    const nodes = useNodes();
    const edges = useEdges();
    const disabled = props.data?.Status != null;
    const [formProps, formCallbacks] = useFormHook();

    const referencesFlowOperationCache = useMemo(() => {
        const node = getNode(props.id);
        const flowOperations: ICreateFlowOperationHookStep[] = [];
        if (node == null) {
            return flowOperations;
        }
        const references: Node[] = getAllReferencesRecursively(node, nodes, edges);
        for (const reference of references) {
            if (reference.type !== HookStepType.FlowOperation) {
                continue;
            }
            const cacheValue = cache[reference.id]?.getForm();
            if (cacheValue == null) {
                continue;
            }
            flowOperations.push(cacheValue as ICreateFlowOperationHookStep);
        }
        return flowOperations;
    }, [nodes, edges, props, getNode, cache]);

    useEffect(() => {
        setCacheItem(props.id, {
            getForm: () => convertGitReleaseFormToStep(formCallbacks.getForm()),
            isFormValid: formCallbacks.isFormValid,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const suggestions = useGetHandlebarSuggestions(props.id);

    return (
        <HooksStepCard label="Git release" {...props} disableActions={disabled} className="h-[400px] w-[400px]">
            <Form variables={getDefaultForm(suggestions, referencesFlowOperationCache, props.data, disabled)}
                defaultExtraValues={{
                    isDraft: props.data?.IsDraft,
                    generateReleaseNotes: props.data?.GenerateReleaseNotes,
                    includePRInformation: props.data?.IncludePRInformation,
                }} {...formProps} />
        </HooksStepCard>
    )
}

const convertGitReleaseFormToStep = (form: IForm): ICreateGitReleaseHookStep => {
    return {
        Type: HookStepType.GitRelease,
        GitRelease: {
            Type: "GitHub",
            FlowId: form.flowId,
            GenerateReleaseNotes: Boolean(form.generateReleaseNotes),
            IsDraft: Boolean(form.isDraft),
            IncludePRInformation: Boolean(form.includePRInformation),
            Message: form.message,
        }
    }
}