
import classNames from "classnames";
import { FC, useCallback, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { CancelButton, DeleteButton, EditButton, SubmitButton, UpdateButton } from "../../../components/button";
import { AdjustableCard, Error, GraphBasedExpandableCard } from "../../../components/card";
import { ClassNames } from "../../../components/classes";
import { Form, IFormGrid, useFormHook } from "../../../components/form";
import { IGraphCardProps } from "../../../components/graph/graph";
import { GraphElements } from "../../../config/constants";
import { Icons } from "../../../config/icons";
import { InternalRoutes } from "../../../config/internal-routes";
import { GetMailIntegrationDocument, GetMailIntegrationsDocument, MailIntegration, useCreateMailIntegrationMutation, useDeleteMailIntegrationMutation, useUpdateMailIntegrationMutation } from "../../../generated/graphql";
import { notify } from "../../../store/function";
import { IDataTransform } from "../../dashboard/context";
import { AddDashboardCard, IAddDashboardCardProps } from "../../dashboard/dashboard-card";
import { GraphCardLoader } from "../../dashboard/graph-card";
import { IRefetch } from "../../../api/query";

export const MAIL_ICON = {
    component: Icons.Mail,
    bgClassName: "bg-black/40 dark:bg-white/10",
};

export type IMailIntegrationsFormProps = {
    onCancel?: () => void;
    onComplete?: (id: string) => void;
    onError?: (error: string) => void;
}

const getDefaultForm = (mailIntegration?: MailIntegration): IFormGrid => {
    return [
        {
            name: "name",
            label: "Name",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Name is required",
            defaultValue: mailIntegration?.Name,
        },
        [
            {
                name: "email",
                label: "Email",
                fieldType: "text",
                validate: (value: string) => value.length > 0,
                errorMessage: "Email is required",
                defaultValue: mailIntegration?.Email,
            },
            {
                name: "password",
                label: "Password",
                fieldType: "password",
                validate: (value: string) => value.length > 0,
                errorMessage: "Password is required",
                defaultValue: mailIntegration?.Password,
            },
        ],
        [
            {
                name: "smtpServer",
                label: "SMTP Server",
                fieldType: "text",
                validate: (value: string) => value.length > 0,
                errorMessage: "SMTP Server is required",
                defaultValue: mailIntegration?.SmtpServer,
            },
            {
                name: "smtpPort",
                label: "SMTP Port",
                fieldType: "text",
                validate: (value: string) => parseInt(value) > 0,
                errorMessage: "SMTP Port must be a valid number",
                defaultValue: mailIntegration?.SmtpPort?.toString(),
            },
        ],
    ];
};


export const CreateMailIntegrationsForm: FC<IMailIntegrationsFormProps> = (props) => {
    const [createMailIntegration, { loading }] = useCreateMailIntegrationMutation();
    const [formProps, { isFormValid, getForm }] = useFormHook();

    const handleSubmit = useCallback(() => {
        const status = isFormValid();
        if (!status.isValid) {
            return props.onError?.(status.errorMessage);
        }
        const form = getForm();
        createMailIntegration({
            variables: {
                request: {
                    Name: form.name,
                    Email: form.email,
                    Password: form.password,
                    SmtpServer: form.smtpServer,
                    SmtpPort: form.smtpPort,
                }
            },
            onCompleted: (data) => {
                notify("Mail integration created successfully", "success");
                props.onComplete?.(data.CreateMailIntegration.Id);
            },
            onError: () => {
                notify("Unable to create mail integration", "error");
            }
        });
    }, [isFormValid, getForm, createMailIntegration, props]);

    const handleClose = useCallback(() => {
        props.onCancel?.();
    }, [props]);

    return (
        <div className="flex flex-col w-full">
            <div className="flex flex-col gap-1 my-4 grow">
                <div className={classNames(ClassNames.Text, "text-lg my-2 flex gap-1 items-center")}><span>{Icons.Mail}</span>Mail Integration</div>
                <Form variables={getDefaultForm()} {...formProps} />
            </div>
            <div className="flex items-center gap-2 justify-end">
                <CancelButton onClick={handleClose} />
                <SubmitButton loading={loading} onClick={handleSubmit} />
            </div>
        </div>
    )
}

type IMailIntegrationsCardProps = {
    refetch: IRefetch;
    mailIntegration: MailIntegration;
}

const MailIntegrationIntro: FC<{ mailIntegration: MailIntegration }> = (props) => {
    return <div className="flex flex-col grow">
        <div className={classNames(ClassNames.Text, "text-md mt-2")}>
            {props.mailIntegration.Name}
        </div>
    </div>
}

export const MailIntegrationsCard: FC<IMailIntegrationsCardProps> = (props) => {
    const [updateMailIntegration, { loading: updateLoading }] = useUpdateMailIntegrationMutation();
    const [deleteMailIntegration, { loading: deleteLoading }] = useDeleteMailIntegrationMutation();
    const toggleCardExpansion = useRef<Function>();
    const [error, setError] = useState("");
    const [formProps, { isFormValid, getForm }] = useFormHook();
    const [searchParams, ] = useSearchParams();

    const handleSubmit = useCallback(() => {
        const status = isFormValid();
        if (!status.isValid) {
            return setError(status.errorMessage);
        }
        const form = getForm();
        updateMailIntegration({
            variables: {
                request: {
                    Id: props.mailIntegration.Id,
                    Name: form.name,
                    Email: form.email,
                    Password: form.password,
                    SmtpServer: form.smtpServer,
                    SmtpPort: form.smtpPort,
                },
            },
            onCompleted: (data) => {
                notify("Mail integration updated successfully", "success");
                props.refetch(data.UpdateMailIntegration.Id);
            },
            onError: () => {
                notify("Unable to updated Mail integration", "error");
            }
        });
    }, [isFormValid, getForm, updateMailIntegration, props]);

    const handleDelete = useCallback(() => {
        deleteMailIntegration({
            variables: {
                request: {
                    Id: props.mailIntegration.Id,
                }
            },
            onCompleted: () => {
                notify("Mail integration deleted successfully!", "success");
                props.refetch?.();
            },
            onError: () => {
                notify("Unable to delete Mail integration", "error");
            },
        });
    }, [props, deleteMailIntegration]);

    const handleOpen = useCallback(() => {
        toggleCardExpansion.current?.(true);
    }, [toggleCardExpansion]);

    const handleCancel = useCallback(() => {
        toggleCardExpansion.current?.(false);
    }, [toggleCardExpansion]);

    return (
        <GraphBasedExpandableCard id={props.mailIntegration.Id} type={GraphElements.MailIntegration} icon={MAIL_ICON} setToggleCallback={(toggle) => toggleCardExpansion.current = toggle}
            tag={<Error error={error} />} highlight={searchParams.get("id") === props.mailIntegration.Id}>
            <>
                <MailIntegrationIntro mailIntegration={props.mailIntegration} />
                <div className="flex">
                    <EditButton onClick={handleOpen} />
                </div>
            </>
            <>
                <div className="flex flex-col gap-1 my-4 grow">
                    <div className={classNames(ClassNames.Text, "text-lg my-2 flex gap-1 items-center")}><span>{Icons.Mail}</span>Mail Integration</div>
                    <Form variables={getDefaultForm(props.mailIntegration)} {...formProps} />
                </div>
                <div className="flex justify-between items-center">
                    <DeleteButton name={props.mailIntegration.Name} loading={deleteLoading} onClick={handleDelete} />
                    <div className="flex items-center gap-2">
                        <CancelButton onClick={handleCancel} />
                        <UpdateButton loading={updateLoading} onClick={handleSubmit} />
                    </div>
                </div>
            </>
        </GraphBasedExpandableCard>
    )
}

export const MailIntegrationDashboardCard: FC<{ mailIntegration: MailIntegration }> = (props) => {
    return <AdjustableCard showTools={true} icon={MAIL_ICON}>
        <MailIntegrationIntro mailIntegration={props.mailIntegration} />
    </AdjustableCard>
}

export const transformMailIntegrationData: IDataTransform<MailIntegration>  = (data: { MailIntegration: MailIntegration[]}) => data.MailIntegration;

export const MailIntegrationGraphCard: FC<IGraphCardProps<MailIntegration>> = (props) => {
    return <GraphCardLoader loader={GetMailIntegrationsDocument} transform={(data) => transformMailIntegrationData(data)[0]} {...props}>
        {(data: MailIntegration) => (<MailIntegrationDashboardCard mailIntegration={data} />)}
    </GraphCardLoader>;
}

export const AddMailIntegrationDashboardCard: FC<IAddDashboardCardProps> = (props) => {
    return (<AddDashboardCard {...props} label="Mail Integration"
        query={GetMailIntegrationDocument}
        transform={transformMailIntegrationData} type={GraphElements.MailIntegration}
        icon={Icons.Mail}
        link={InternalRoutes.Integrations.CreateIntegrations.path} />
    );
}