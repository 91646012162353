import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { FC, ReactElement, ReactNode, cloneElement, useCallback, useState } from "react";
import { twMerge } from "tailwind-merge";
import { AnimatedDropdown } from "./dropdown";
import { Loading } from "./loading";
import { Icons } from "../config/icons";
import { ClassNames } from "./classes";
import { createPortal } from "react-dom";

export type IButtonProps = {
  className?: string;
  label: ReactNode;
  loading?: boolean;
  loadingLabel?: string;
  icon: ReactElement;
  iconClassName?: string;
  labelClassName?: string;
  onClick?: () => void;
  disabled?: boolean;
}

export const Button: FC<IButtonProps> = (props) => {
  return <motion.button className={twMerge(classNames(ClassNames.Button, ClassNames.Text, props.className, {
    "cursor-not-allowed": props.disabled || props.loading,
    "opacity-50": props.disabled,
  }))} onClick={props.disabled || props.loading ? undefined : props.onClick} disabled={props.disabled} whileTap={{ scale: 0.8 }}>
    <div className={classNames("text-xs", props.labelClassName)}>
      {props.loading ? props.loadingLabel ?? "Submitting" : props.label}
    </div>
    {
      props.loading
      ? <Loading size="sm" />
      : cloneElement(props.icon, {
          className: classNames("w-4 h-4", props.iconClassName),
        })
    }
  </motion.button>
}

export const AnimatedButton: FC<IButtonProps> = (props) => {
  return <Button {...props} className={twMerge(classNames("transition-all hover:gap-2", props.className))} />
}

type IAnimatedButtonDropdownProps = {
  buttons: IButtonProps[];
}

export const AnimatedButtonDropdown: FC<IAnimatedButtonDropdownProps> = (props) => {
  return <AnimatedDropdown items={props.buttons.map(button => (
    <Button {...button} className={twMerge(classNames("transition-all hover:gap-2 justify-start whitespace-nowrap", button.className))} />
  ))} /> 
}

type IToggleButtonProps = {
  checked: boolean;
  label: string;
  onClick: () => void;
}

export const ToggleButton: FC<IToggleButtonProps> = (props) => {
  return (<label className="relative inline-flex items-center mb-5 cursor-pointer">
    <input type="checkbox" value="" className="sr-only peer" checked={props.checked} onClick={props.onClick} />
    <div className="w-11 h-6 bg-neutral-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-neutral-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-teal-500"></div>
    <span className={classNames(ClassNames.Text, "ms-3 text-sm font-medium")}>{props.label}</span>
  </label>)
}

export type IActionButtonProps = {
  icon: ReactElement;
  className?: string;
  containerClassName?: string;
  onClick?: () => void;
  disabled?: boolean;
  children?: ReactNode;
}

export const ActionButton: FC<IActionButtonProps> = ({ onClick, icon, className, containerClassName, disabled, children }) => {
  return (
  <div className="group relative">
    <motion.button className={twMerge(classNames("rounded-full bg-white dark:bg-white/5 dark:hover:bg-white/10 backdrop-blur-lg h-12 w-12 transition-all border border-neutral-300 dark:border-neutral-300/10 shadow-sm flex items-center justify-center", containerClassName, {
      "cursor-not-allowed": disabled,
      "hover:shadow-lg hover:cursor-pointer hover:scale-110": !disabled,
    }))} onClick={disabled ? undefined : onClick} whileTap={{ scale: 0.6, transition: { duration: 0.05 }, }}>
      {cloneElement(icon, {
          className: twMerge(classNames("w-8 h-8 stroke-neutral-500 dark:stroke-neutral-300 cursor-pointer", className))
      })}
    </motion.button>
    {children}
  </div>);
}

type IActionButtonsProps = {
  buttons: IActionButtonProps[];
}

export const ActionButtons: FC<IActionButtonsProps> = ({ buttons }) => {
  return <AnimatedDropdown className="group action-buttons" reverse={true} items={buttons.map(button => 
    <ActionButton {...button} />
  )} />
}

type ITemplateButtonProps = {
  loading?: boolean;
  onClick?: () => void;
}

export const SubmitButton: FC<ITemplateButtonProps> = (props) => <AnimatedButton label="Submit" loading={props.loading} icon={Icons.CheckCircle} labelClassName="text-green-800 dark:text-green-500" iconClassName="text-green-600 dark:text-green-500" onClick={props.onClick} />;
export const UpdateButton: FC<ITemplateButtonProps> = (props) => <AnimatedButton label="Update" loading={props.loading} loadingLabel="Updating" icon={Icons.CheckCircle} labelClassName="text-green-800 dark:text-green-500" iconClassName="text-green-600 dark:text-green-500" onClick={props.onClick} />;
export const CancelButton: FC<ITemplateButtonProps> = (props) => <AnimatedButton label="Cancel" icon={Icons.Cancel} onClick={props.onClick} labelClassName="text-amber-800 dark:text-amber-500" iconClassName="text-amber-600 dark:text-amber-500" />;
export const EditButton: FC<ITemplateButtonProps> = (props) => <AnimatedButton label="Edit" icon={Icons.Edit} onClick={props.onClick} iconClassName="w-3 h-3" />;
export const CreateButton: FC<ITemplateButtonProps> = (props) => <AnimatedButton label="Create" icon={Icons.Add} onClick={props.onClick} />;

export const DeleteButton: FC<ITemplateButtonProps & { shouldConfirm?: boolean, name: string }> = ({
  name,
  shouldConfirm = true,
  loading,
  onClick
}) => {
  const [showPanel, setShowPanel] = useState(false);

  const handleClick = useCallback(() => {
    setShowPanel(true);
  }, []);

  const handleClosePanel = useCallback(() => {
    setShowPanel(false);
  }, []);

  if (!shouldConfirm) {
    return <AnimatedButton label="Delete" loading={loading} loadingLabel="Deleting" icon={Icons.Delete} labelClassName="text-red-800 dark:text-red-500" iconClassName="text-red-600 dark:text-red-500" onClick={handleClick} />;
  }

  return <>
      {
        createPortal(<AnimatePresence mode="wait">
            {showPanel && <motion.div className={twMerge(classNames("fixed top-5 right-5 h-[calc(100%-40px)] w-fit bg-white dark:bg-neutral-800 shadow p-8 z-[50] rounded-2xl border border-black/5 dark:border-white/5", ))}
                initial={{ x: 200, opacity: 0 }}
                animate={{ x: 0, opacity: 100 }}
                exit={{ x: 100, opacity: 0 }}>
                <div className="flex flex-col gap-4 h-full justify-center items-center max-w-[250px]">
                    <div className="absolute top-0 left-0 -translate-x-1/2 -translate-y-1/2">
                        <ActionButton className="w-6 h-6" containerClassName="w-8 h-8 bg-white dark:bg-neutral-800" icon={Icons.Cross} onClick={handleClosePanel} />
                    </div>
                    <div className={classNames(ClassNames.Text, "text-lg text-center")}>
                      Are you sure you would like to delete {name}?
                    </div>
                    <AnimatedButton label="Confirm deletion" loadingLabel="Deleting" loading={loading} icon={Icons.Delete} onClick={onClick} labelClassName="text-red-800 dark:text-red-500" iconClassName="text-red-600 dark:text-red-500" />
                    <div className={classNames(ClassNames.DisabledText, "text-sm text-center mt-8")}>
                      This action is not reversible.
                    </div>
                </div>
            </motion.div>}
        </AnimatePresence>,
        document.querySelector(`.${ClassNames.AppContainer}`)!)
    }
    <AnimatedButton label="Delete" loading={loading} loadingLabel="Deleting" icon={Icons.Delete} labelClassName="text-red-800 dark:text-red-500" iconClassName="text-red-600 dark:text-red-500" onClick={handleClick} />
  </>
}