import classNames from "classnames";
import { keyBy, keys, mapValues, omitBy } from "lodash";
import { FC, ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { useImmer } from "use-immer";
import { AnimatedButton, CancelButton, DeleteButton } from "../../../components/button";
import { ClassNames } from "../../../components/classes";
import { CheckboxInput, InputWithlabel } from "../../../components/input";
import { Icons } from "../../../config/icons";
import { useGetOAuthUrlMutation } from "../../../generated/graphql";
import { IRefetch } from "../../../api/query";
import { notify } from "../../../store/function";

export enum OAuthElements {
    GITHUB = "github",
    DIGITAL_OCEAN = "digitalocean",
    GOOGLE = "google",
}

type IOAuthLoginCardProps = {
    name?: string;
    label: string;
    icon: ReactElement;
    element: OAuthElements;
    onCancel: () => void;
    scopes: {
        scope: string;
        description: string;
        checked?: boolean,
    }[];
    hideTitle?: boolean;
    onDelete?: () => void;
    id?: string;
    refetch?: IRefetch;
}

export function getOAuthUrlCallbackLocationStorageCacheId(element: OAuthElements) {
    return `intergrations-${element}-oauth-callback-data`;
}

export const OAuthLoginCard: FC<IOAuthLoginCardProps> = (props) => {
    const [getOAuthUrl, { loading }] = useGetOAuthUrlMutation();
    const [name, setName] = useState(props.name ?? "");
    const [scopesForm, setScopesForm] = useImmer(mapValues(keyBy(props.scopes.map(scope => ({
        name: scope.scope,
        value: scope.checked ?? false,
    })), "name"), "value"));
    const oauthCachePollInterval = useRef<NodeJS.Timeout>();

    const handleLogin = useCallback(() => {
        if (loading) {
            return;
        }
        const scopes = keys(omitBy(scopesForm, (checked) => !checked));
        getOAuthUrl({
            variables: {
                id: props.id,
                type: props.element,
                name,
                scopes,
            },
            onCompleted(data) {
                const windowRef = window.open(data.GetOAuthUrl.Url, "_blank");
                oauthCachePollInterval.current = setInterval(() => {
                    const cacheId = getOAuthUrlCallbackLocationStorageCacheId(props.element);
                    const oauthCacheDataString = localStorage.getItem(cacheId);
                    if (oauthCacheDataString != null) {
                        const oauthCacheData: { id?: string, type: "success" | "error", message: string } = JSON.parse(oauthCacheDataString);
                        notify(oauthCacheData.message, oauthCacheData.type);
                        localStorage.removeItem(cacheId);
                        clearInterval(oauthCachePollInterval.current);
                        oauthCachePollInterval.current = undefined;
                        props.refetch?.(oauthCacheData.id);
                        windowRef?.close();
                    }
                }, 500);
            },
        });
    }, [name, scopesForm, getOAuthUrl, loading, props]);

    useEffect(() => {
        return () => {
            if (oauthCachePollInterval.current != null) {
                clearInterval(oauthCachePollInterval.current);
            }
        }
    }, []);

    return <div className="flex flex-col w-full grow">
        <div className="flex flex-col my-2 grow">
            {
                !props.hideTitle &&
                <div className={classNames(ClassNames.Text, "text-lg my-2 flex gap-1 items-center")}><span>{props.icon}</span>{props.label}</div>
            }
            <InputWithlabel label="Name" value={name} setValue={setName} />
            <div className="flex flex-col gap-1 h-[150px] overflow-y-scroll mt-2">
                {
                    props.scopes.map(scope => (
                        <div className="flex flex-row py-2 gap-2 px-1">
                            <CheckboxInput className="mt-1" checked={scopesForm[scope.scope]} onChecked={(checked) => setScopesForm(scopes => {
                                scopes[scope.scope] = checked;
                                return scopes;
                            })} />
                            <div className="flex flex-col">
                                <div className={classNames(ClassNames.Text, "text-sm font-bold")}>
                                    {scope.scope}
                                </div>
                                <div className={classNames(ClassNames.Text, "text-xs")}>
                                    {scope.description}
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
        <div className={classNames("flex items-center", {
            "justify-between": props.onDelete != null,
            "justify-end": props.onDelete == null,
        })}>
            {
                props.onDelete != null &&
                <DeleteButton name={props.label} onClick={props.onDelete} />
            }
            <div className="flex items-center gap-2">
                <CancelButton onClick={props.onCancel} />
                <AnimatedButton label={props.id != null ? "Update" : "Login"} icon={Icons.RightArrowUp} labelClassName="text-green-800 dark:text-green-500" iconClassName="text-green-600 dark:text-green-500" onClick={handleLogin} />
            </div>
        </div>
    </div>
}