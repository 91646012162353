import classNames from "classnames";
import { keys, map, once, times } from "lodash";
import { cloneElement, FC, useCallback, useEffect, useMemo, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useImmer } from "use-immer";
import { ActionButton, AnimatedButton, DeleteButton, UpdateButton } from "../../../components/button";
import { Error } from "../../../components/card";
import { ILineChartData, LineChart } from "../../../components/charts/line-chart";
import { ClassNames } from "../../../components/classes";
import { LoadingPage, Page } from "../../../components/container";
import { Dropdown } from "../../../components/dropdown";
import { Form, useFormHook } from "../../../components/form";
import { Label } from "../../../components/input";
import { Loading } from "../../../components/loading";
import { LogViewer } from "../../../components/log-viewer";
import { Pill } from "../../../components/pill";
import { Icons } from "../../../config/icons";
import { InternalRoutes } from "../../../config/internal-routes";
import { QuickContainer, useDeleteQuickContainerMutation, useGetQuickContainerInstanceLogsQuery, useGetQuickContainerInstancesQuery, useGetQuickContainerLazyQuery, useGetQuickContainerMetricsSubscription, useGetQuickContainerStatusSubscription, useRestartQuickContainerMutation, useStartQuickContainerMutation, useStopQuickContainerMutation, useUpdateQuickContainerMutation } from "../../../generated/graphql";
import { notify } from "../../../store/function";
import { getOptional, getOptionalNumber } from "../../../utils/functions";
import { getAdvancedDefaultForm, getDataDefaultForm, getDetailsDefaultForm, OpenLinkButton } from "./quick-container-card";
import { IRefetch } from "../../../api/query";

const QuickContainerLogs: FC<{ quickContainer: QuickContainer, instanceMap: Record<string, string>, instanceNames: string[] }> = ({ quickContainer, instanceMap, instanceNames }) => {
    const [instanceName, setInstanceName] = useState(instanceNames[0]);
    const { data, loading, refetch } = useGetQuickContainerInstanceLogsQuery({
        variables: {
            id: quickContainer.Id,
            instanceName,
        },
    });

    const handleRefresh = useCallback(() => {
        refetch();
    }, [refetch]);

    if (data?.QuickContainerLogs == null || loading) {
        return <Loading hideText={true}  />
    }

    return (
        <div className="flex flex-col gap-2 items-start justify-start grow">
            <div className="flex justify-between items-center w-full">
                <Label className="text-lg" label="Logs" />
                <ActionButton icon={Icons.Refresh} className="w-3 h-3" containerClassName="w-6 h-6" onClick={handleRefresh} />
            </div>
            <LogViewer className="max-h-[50vh] max-w-[40.1vw]" logs={data.QuickContainerLogs[0].Logs} actions={instanceNames.length === 1 ? undefined : <div className="flex max-w-[250px] items-center gap-1 h-full">
                <Dropdown className="h-full" items={instanceNames.map(instanceName => ({
                    id: instanceName,
                    label: instanceMap[instanceName],
                }))} onClick={(item) => setInstanceName(item.id)}>
                    <div className={classNames(ClassNames.Text, "flex flex-row gap-1 text-sm border px-2 py-1 border-neutral-200 dark:border-neutral-200/10 rounded-md justify-center items-center cursor-pointer h-full")}>
                        {instanceMap[instanceName]}
                        {cloneElement(Icons.DownCaret, {
                            className: "w-4 h-4 stroke-neutral-500",
                        })}
                    </div>
                </Dropdown>
            </div>} />
        </div>
    )
}

const getBasicDates = once(() => {
    const date = new Date();
    return map([...new Array(10)], (_, i) => new Date(date.getTime() - (10000 - i*1000)))
});

const getBasicData = once((instanceNames: string[], instanceMap: Record<string, string>) => {
    return times(10, () => instanceNames.reduce((all, instanceName) => {
        all[instanceMap[instanceName]] = 0;
        return all;
    }, {} as Record<string, number>));
});

const QuickContainerMetricsDetails: FC<{ quickContainer: QuickContainer, instanceMap: Record<string, string>, instanceNames: string[] }> = ({ quickContainer, instanceMap, instanceNames }) => {
    const [cpu, setCPU] = useImmer<ILineChartData>({ x: getBasicDates(), y: getBasicData(instanceNames, instanceMap) });
    const [ram, setRAM] = useImmer<ILineChartData>({ x: getBasicDates(), y: getBasicData(instanceNames, instanceMap) });
    
    const { data, loading } = useGetQuickContainerMetricsSubscription({
        variables: {
            id: quickContainer.Id,
            instanceNames,
        },
        onData({ data: { data }}) {
            if (data?.ListenQuickContainerMetrics == null) {
                return;
            }
            const instanceName = data.ListenQuickContainerMetrics.InstanceName;
            const date = new Date(data.ListenQuickContainerMetrics.Time * 1000);
            setCPU(cpu => {
                if (cpu.x.at(-1)?.getTime() === date.getTime()) {
                    cpu.y.at(-1)![instanceMap[instanceName]] = data.ListenQuickContainerMetrics.CPU;
                    return;
                }
                cpu.x.push(date);
                cpu.y.push({
                    [instanceMap[instanceName]]: data.ListenQuickContainerMetrics.CPU,
                });
            });
            setRAM(ram => {
                if (ram.x.at(-1)?.getTime() === date.getTime()) {
                    ram.y.at(-1)![instanceMap[instanceName]] = data.ListenQuickContainerMetrics.RAM;
                    return;
                }
                ram.x.push(date);
                ram.y.push({
                    [instanceMap[instanceName]]: data.ListenQuickContainerMetrics.RAM,
                });
            });
        },
        onError() {}
    });

    if (loading) {
        return <Loading hideText={true}  />
    }

    return (
        <>
            <div className="flex flex-col gap-2">
                {
                    (data?.ListenQuickContainerMetrics.Restarts ?? 0) > 0 &&
                    <div className={twMerge(classNames(ClassNames.Text, "text-sm"))}>
                        <span className="font-bold">Restarts:</span> {data?.ListenQuickContainerMetrics.Restarts}
                    </div>
                }
                {data?.ListenQuickContainerMetrics.ReasonForWaiting != null && <div className={twMerge(classNames(ClassNames.Text, "text-sm text-amber-500 dark:text-amber-500"))}>
                    <span className="font-bold">Waiting reason:</span> {data?.ListenQuickContainerMetrics.ReasonForWaiting}
                </div>}
                {data?.ListenQuickContainerMetrics.ReasonForRestart != null && <div className={twMerge(classNames(ClassNames.Text, "text-sm text-amber-500 dark:text-amber-500"))}>
                    <span className="font-bold">Restart reason:</span> {data?.ListenQuickContainerMetrics.ReasonForRestart}
                </div>}
            </div>
            <div className="min-h-[75px] relative w-full">
                <Pill id="CPU" label="CPU" className="absolute left-0 top-1 text-[10px] z-5 shadow-md dark:bg-white/10 dark:text-neutral-300" />
                <LineChart x={cpu.x} y={cpu.y} />
            </div>
            <div className="min-h-[75px] relative w-full">
                <Pill id="RAM" label="RAM" className="absolute left-0 top-1 text-[10px] z-5 shadow-md dark:bg-white/10 dark:text-neutral-300" />
                <LineChart x={ram.x} y={ram.y}  />
            </div>
        </>
    )
}

export const QuickContainerMetricsCard: FC<{ status: string, quickContainer: QuickContainer, showLogs: boolean }> = ({ status, quickContainer, showLogs }) => {
    const { data, loading } = useGetQuickContainerInstancesQuery({
        variables: {
            id: quickContainer.Id,
        },
    });

    const instanceMap = useMemo(() => {
        return (data?.QuickContainerInstances ?? []).reduce((map, instance, i) => {
            map[instance] = `Instance ${i+1}`; 
            return map;
        }, {} as Record<string ,string>);
    }, [data?.QuickContainerInstances]);

    const mapSize = useMemo(() => {
        return keys(instanceMap).length;
    }, [instanceMap]);

    if (data?.QuickContainerInstances == null || loading || mapSize === 0) {
        return <Loading hideText={true}  />
    }

    return <div className="flex flex-col w-full h-full gap-8">
        <div className="flex flex-col gap-2">
            { showLogs && <Label className="text-lg" label="Metrics" /> }
            <QuickContainerMetricsDetails quickContainer={quickContainer} instanceMap={instanceMap} instanceNames={data.QuickContainerInstances} />
        </div>
        {
            showLogs && status === "Started" && <div className="flex flex-col gap-2">
                <QuickContainerLogs quickContainer={quickContainer} instanceMap={instanceMap} instanceNames={data.QuickContainerInstances} />
            </div>
        }
    </div>
}

type IExploreQuickContainerProps = {
    quickContainer: QuickContainer;
    refetch: IRefetch;
}

const ExploreQuickContainer: FC<IExploreQuickContainerProps> = (props) => {
    const navigate = useNavigate();
    const [error, setError] = useState("");

    const [detailsFormProps, { isFormValid: isDetailsFormValid, getForm: getDetailsForm }] = useFormHook({
        name: props.quickContainer.Name,
        imageId: props.quickContainer?.Containers[0].RegistryImageId,
        tag: props.quickContainer?.Containers[0].Tag,
        ports: props.quickContainer?.Containers[0].Ports,
    });
    const [dataFormProps, { isFormValid: isDataFormValid, getForm: getDataForm }] = useFormHook({
        volumes: props.quickContainer?.Containers[0].Volumes ?? [],
        size: props.quickContainer.Size?.toString() ?? "10",
        environmentVariableId: props.quickContainer?.Containers[0].EnvironmentVariableId,
    });
    const [advancedFormProps, { isFormValid: isAdvancedFormValid, getForm: getAdvancedForm }] = useFormHook({
        cpu: props.quickContainer?.Containers[0].ContainerResource?.LimitsCPU?.toString() ?? "100",
        ram: props.quickContainer?.Containers[0].ContainerResource?.LimitsMemory?.toString() ?? "100",
        domainId: props.quickContainer?.Domain?.Id,
        clusterId: props.quickContainer?.Cluster?.Id,
    });

    const [updateQuickContainer, { loading: updateLoading }] = useUpdateQuickContainerMutation();
    const [deleteQuickContainer, { loading: deleteLoading }] = useDeleteQuickContainerMutation();
    const [startQuickContainer, { loading: startLoading }] = useStartQuickContainerMutation();
    const [stopQuickContainer, { loading: stopLoading }] = useStopQuickContainerMutation();
    const [restartQuickContainer, { loading: restartLoading }] = useRestartQuickContainerMutation();

    const { data: statusData } = useGetQuickContainerStatusSubscription({
        variables: {
            ids: [props.quickContainer.Id],
        },
    })

    const handleStart = useCallback(() => {
        startQuickContainer({
            variables: {
                id: props.quickContainer.Id,
            },
            onCompleted: () => {
                notify(`Starting ${props.quickContainer.Name}!`, "success");
                props.refetch();
            },
            onError: () => {
                notify(`Unable to start ${props.quickContainer.Name}`, "error");
            },
        });
    }, [props, startQuickContainer]);

    const handleStop = useCallback(() => {
        stopQuickContainer({
            variables: {
                id: props.quickContainer.Id,
            },
            onCompleted: () => {
                notify(`Stopping ${props.quickContainer.Name}!`, "success");
                props.refetch();
            },
            onError: () => {
                notify(`Unable to stop ${props.quickContainer.Name}`, "error");
            },
        });
    }, [props, stopQuickContainer]);

    const handleRestart = useCallback(() => {
        restartQuickContainer({
            variables: {
                id: props.quickContainer.Id,
            },
            onCompleted: () => {
                notify(`Restarting ${props.quickContainer.Name}!`, "success");
                props.refetch();
            },
            onError: () => {
                notify(`Unable to restart ${props.quickContainer.Name}`, "error");
            },
        });
    }, [props, restartQuickContainer]);

    const handleDelete = useCallback(() => {
        deleteQuickContainer({
            variables: {
                id: props.quickContainer.Id,
            },
            onCompleted: () => {
                notify(`Deleted ${props.quickContainer.Name} successfully`, "success");
                navigate(InternalRoutes.Deployment.QuickContainer.path, {
                    state: {
                        refetch: true,
                    }
                });
            },
            onError: () => {
                notify(`Unable to delete ${props.quickContainer.Name}`, "error");
            },
        });
    }, [deleteQuickContainer, navigate, props.quickContainer.Id, props.quickContainer.Name]);

    const handleUpdate = useCallback(() => {
        let state = isDetailsFormValid();
        if (!state.isValid) {
            return setError(state.errorMessage);
        }
        state = isDataFormValid();
        if (!state.isValid) {
            return setError(state.errorMessage);
        }
        state = isAdvancedFormValid();
        if (!state.isValid) {
            return setError(state.errorMessage);
        }
        setError("");
        const detailsForm = getDetailsForm();
        const dataForm = getDataForm();
        const advancedForm = getAdvancedForm();
        updateQuickContainer({
            variables: {
                request: {

                    Id: props.quickContainer.Id,
                    Name: detailsForm.name,
                    Containers: [
                        {
                            RegistryImageId: detailsForm.imageId,
                            Tag: detailsForm.tag,
                            Ports: map(detailsForm.ports, port => parseInt(port)),
                            Resource: {
                                LimitsMemory: advancedForm.memory ?? "100",
                                LimitsCPU: advancedForm.cpu ?? "100",
                                RequestsMemory: advancedForm.memory ?? "100",
                                RequestsCPU: advancedForm.cpu ?? "100",
                            },
                            Volumes: dataForm.volumes ?? [],
                            EnvironmentVariableId: getOptional(dataForm.environmentVariableId),
                        },
                    ],
                    DomainId: getOptional(advancedForm.domainId),
                    Size: getOptionalNumber(dataForm.size),
                    ClusterId: getOptional(advancedForm.clusterId),
                    Instances: getOptionalNumber(advancedForm.instances),
                    Internal: Boolean(advancedForm.internal),
                },
            },
            onCompleted: (data) => {
                notify("Quick container updated successfully", "success");
                props.refetch(data.UpdateQuickContainer.Id);
            },
            onError: () => {
                notify("Unable to update quick container", "error");
            }
        });
    }, [isDetailsFormValid, isDataFormValid, isAdvancedFormValid, getDetailsForm, getDataForm, getAdvancedForm, updateQuickContainer, props]);

    const status = useMemo(() => {
        return statusData?.ListenQuickContainerStatus.Status ?? props.quickContainer.Status;
    }, [props.quickContainer.Status, statusData?.ListenQuickContainerStatus.Status]);

    return <div className="flex flex-col gap-8 w-full h-full">
        <div className="flex justify-between items-center">
            <div className="flex items-center gap-4">
                <div className={classNames(ClassNames.Text, "text-3xl")}>
                    {props.quickContainer.Name}
                </div>
                <Pill id="status" label={status} />
            </div>
            <div className="flex items-center gap-2">
                <DeleteButton name={props.quickContainer.Name} onClick={handleDelete} loading={deleteLoading} />
                <AnimatedButton disabled={status !== "Started"} icon={Icons.RectangularArrowLoop} onClick={handleRestart} label="Restart" labelClassName="text-orange-800 dark:text-orange-500" iconClassName="text-orange-600 dark:text-orange-500" loading={restartLoading} />
                {
                    (status === "Starting" || status === "Started") &&
                    <AnimatedButton icon={Icons.Stop} onClick={handleStop} label="Stop" labelClassName="text-orange-800 dark:text-orange-500" iconClassName="text-orange-600 dark:text-orange-500" loading={stopLoading} />
                }
                {
                    (status === "Created" || status === "Stopped") &&
                    <AnimatedButton icon={Icons.Play} onClick={handleStart} label="Start" labelClassName="text-green-800 dark:text-green-500" iconClassName="text-green-600 dark:text-green-500" loading={startLoading} />
                }
                <OpenLinkButton quickContainer={props.quickContainer} />
            </div>
        </div>
        <div className="flex gap-16 h-full">
            <div className="flex flex-col justify-start gap-8 flex-1">
                <div className="flex flex-col gap-2">
                    <div className="flex justify-between items-center">
                        <Label className="text-lg" label="Basic" />
                        <div className="flex items-center gap-2">
                            <Error error={error} />
                            <UpdateButton onClick={handleUpdate} loading={updateLoading} />
                        </div>
                    </div>
                    <Form variables={getDetailsDefaultForm(props.quickContainer)} defaultExtraValues={getDetailsForm()} {...detailsFormProps} />
                </div>
                <div className="flex flex-col gap-2">
                    <Label className="text-lg" label="Data" />
                    <Form variables={getDataDefaultForm(props.quickContainer)} defaultExtraValues={getDataForm()} {...dataFormProps} />
                </div>
                <div className="flex flex-col gap-2">
                    <Label className="text-lg" label="Advanced" />
                    <Form variables={getAdvancedDefaultForm(props.quickContainer)} defaultExtraValues={getAdvancedForm()} {...advancedFormProps} />
                </div>
                <div className="flex flex-col gap-2">
                    <Label className="text-lg" label="CI/CD" />
                    <div className={classNames(ClassNames.Text, "text-lg")}>
                        Set up a CI/CD pipeline using Flows and Hooks. Flows enable you to build and push to your registry, while Hooks integrate with GitHub and unlock advanced features.                    </div>
                    <div className="flex justify-end items-center gap-2">
                        <AnimatedButton icon={Icons.CircleRightArrow} label="Create Flow" onClick={() => navigate(InternalRoutes.CI_CD.CreateFlow.path)} />
                        <AnimatedButton icon={Icons.CircleRightArrow} label="Create Hook" onClick={() => navigate(InternalRoutes.CI_CD.CreateHook.path)} />
                    </div>
                </div>
            </div>
            <div className="flex flex-col flex-1">
                {
                    !["Started", "Starting"].includes(status)
                    ? <div className="flex flex-col h-full items-center justify-center gap-4 text-lg">
                        <div className={classNames(ClassNames.Text, "flex items-center gap-2")}>
                            {cloneElement(Icons.Play, {
                                className: "w-6 h-6",
                            })} 
                            Start the container to see metrics and logs
                        </div>
                        {
                            (status === "Created" || status === "Stopped") &&
                            <AnimatedButton icon={Icons.Play} onClick={handleStart} label="Start" labelClassName="text-green-800 dark:text-green-500" iconClassName="text-green-600 dark:text-green-500" loading={startLoading} />
                        }
                    </div>
                    : <QuickContainerMetricsCard status={status} quickContainer={props.quickContainer} showLogs={true} />
                }
            </div>
        </div>
    </div>
}

export const ExploreQuickContainerPage: FC = () => {
    const { id } = useParams<{ id: string }>();
    const [getQuickContainer, { data: quickContainer, loading, refetch }] = useGetQuickContainerLazyQuery()
    const navigate = useNavigate();

    useEffect(() => {
        if (id == null) {
            return;
        }
        getQuickContainer({
            variables: {
                id,
            },
            onError() {
                navigate(InternalRoutes.Deployment.QuickContainer.path);
            },
        });
    }, [getQuickContainer, id, navigate]);

    if (id == null) {
        return <Navigate to={InternalRoutes.Dashboard.path} />
    }

    if (loading || quickContainer?.QuickContainer == null) {
        return <LoadingPage />
    }

    return <Page routes={[InternalRoutes.Deployment.QuickContainer, InternalRoutes.Deployment.ExploreQuickContainer]}>
        <ExploreQuickContainer quickContainer={quickContainer.QuickContainer[0] as QuickContainer} refetch={refetch} />
    </Page>
}