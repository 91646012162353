import { cloneElement, FC, useCallback, useRef, useState } from "react";
import { CancelButton, DeleteButton, EditButton, SubmitButton, UpdateButton } from "../../components/button";
import { Error, CreateCard, ExpandableCard } from "../../components/card";
import { Dropdown } from "../../components/dropdown";
import { Form, IFormGrid, useFormHook } from "../../components/form";
import { Pill, Pills } from "../../components/pill";
import { Icons } from "../../config/icons";
import { useAssignUserRoleMutation, useCreateUserMutation, useDeleteUserMutation, useGetAllRolesQuery, User } from "../../generated/graphql";
import { notify } from "../../store/function";
import { validateEmail } from "../../utils/functions";
import { ClassNames } from "../../components/classes";
import classNames from "classnames";
import { IRefetch } from "../../api/query";

type IAddRolesProps = {
  roles: string[];
  onClick: (role: string) => void;
}

const AddRoles: FC<IAddRolesProps> = (props) => {
  return (
    <Dropdown items={props.roles.map(role => ({ label: role, id: role }))} onClick={(role) => props.onClick(role.label)} noItemsLabel="No roles found">
      <button type="button" className="rounded-full transition-all flex self-center hover:scale-110">
        {cloneElement(Icons.Add, {
          className: "w-6 h-6 stroke-green-800 dark:stroke-green-500",
        })}
      </button>
    </Dropdown>
  )
}

function getDefaultForm(user?: Pick<User, "Id" | "FirstName" | "LastName" | "Email" | "Image" | "Roles">): IFormGrid {
  return [
      {
          name: "firstName",
          label: "First name",
          fieldType: "text",
          validate: (value: string) => value.length > 0,
          errorMessage: "First name is required",
          defaultValue: user?.FirstName,
          disabled: user != null,
      },
      {
        name: "lastName",
        label: "Last name",
        fieldType: "text",
        validate: (value: string) => value.length > 0,
        errorMessage: "Last name is required",
        defaultValue: user?.LastName,
        disabled: user != null,
      },
      {
        name: "email",
        label: "Email",
        fieldType: "text",
        validate: (value: string) => value.length > 0 && !!validateEmail(value),
        errorMessage: "Valid email is required",
        defaultValue: user?.Email,
        disabled: user != null,
      },
    ]
  }

type ICreateUserCard = {
  refetch: IRefetch;
}

export const CreateUserCard: FC<ICreateUserCard> = ({ refetch }) => {
  const [error, setError] = useState("");
  const [createUser] = useCreateUserMutation();
  const { loading, data: allRoles } = useGetAllRolesQuery();
  const ref = useRef<Function>();

  const [formProps, { getForm, isFormValid }] = useFormHook();
  const [roles, setRoles] = useState<string[]>([]);

  const handleAdd = useCallback((roleName: string) => {
    setRoles(roles => Array.from(new Set([...roles, roleName])));
  }, []);

  const handleRemove = useCallback((roleName: string) => {
    setRoles(roles => roles.filter(role => role !== roleName));
  }, []);

  const handleSubmit = useCallback(() => {
    const state = isFormValid();
    if (!state.isValid) {
      return setError(state.errorMessage);
    }
    if (roles.length === 0) {
      return setError("At least on role is required");
    }
    setError("");
    const form = getForm();
    createUser({
      variables: {
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email,
        roles,
      },
      onCompleted: () => {
        notify("User created successfully", "success");
        setRoles([]);
        ref.current?.();
        refetch();
      },
      onError: () => {
        notify("Unable to create user", "error");
      }
    });
  }, [isFormValid, roles, getForm, createUser, refetch]);

  return (
    <>
      <CreateCard label="User" icon={{
        component: Icons.Admin.User.Create,
        bgClassName: "bg-teal-500",
      }} loading={loading} setToggleCallback={(toggle) => ref.current = toggle}
        tag={<Error error={error} />}>
        <>
          <div className="flex flex-col gap-1 my-4 grow">
            <div>
              <Form variables={getDefaultForm()} {...formProps} />
            </div>
            <strong><label className="text-xs text-neutral-600 mt-2">Roles</label></strong>
            <div className="flex items-center gap-1 flex-wrap">
              {roles.map(role => (
                <Pill id={role} label={role} handleRemove={handleRemove} />
              ))}
              <AddRoles roles={allRoles?.AllRoles ?? []} onClick={handleAdd} />
            </div>
          </div>
          <div className="flex justify-end items-center gap-2">
            <CancelButton onClick={() => ref.current?.()} />
            <SubmitButton loading={loading} onClick={handleSubmit} />
          </div>
        </>
      </CreateCard>
    </>
  )
}

type IUserCardProps = {
  user: Pick<User, "Id" | "FirstName" | "LastName" | "Email" | "Image" | "Roles">;
  refetch: IRefetch;
};

export const UserCard: FC<IUserCardProps> = (props) => {
  const allRolesResponse = useGetAllRolesQuery();
  const [roles, setRoles] = useState<string[]>(props.user.Roles);
  const [error, setError] = useState("");
  const [assignUserRoles, { loading: updateLoading }] = useAssignUserRoleMutation();
  const [deleteUser, { loading: deleteLoading }] = useDeleteUserMutation();
  const [formProps, { getForm }] = useFormHook();

  const toggleCardExpansion = useRef<Function>();

  const handleAdd = useCallback((roleName: string) => {
    setRoles(roles => Array.from(new Set([...roles, roleName])));
  }, []);

  const handleRemove = useCallback((roleName: string) => {
    setRoles(roles => roles.filter(role => role !== roleName));
  }, []);

  const handleCancel = useCallback(() => {
    toggleCardExpansion.current?.();
    setRoles(props.user.Roles);
    setError("");
  }, [props, toggleCardExpansion]);

  const handleSubmit = useCallback(() => {
    if (roles.length === 0) {
      return setError("At least on role is required");
    }
    setError("");
    assignUserRoles({
      variables: {
        id: props.user.Id,
        roles,
      },
      onCompleted: () => {
        notify("User updated successfully!", "success");
      },
      onError: () => {
        notify("Unable to update user", "error");
      },
    });
  }, [props, roles, assignUserRoles]);

  const handleDelete = useCallback(() => {
    deleteUser({
      variables: {
        Id: props.user.Id,
      },
      onCompleted: () => {
        notify("User deleted successfully!", "success");
        props.refetch();
      },
      onError: () => {
        notify("Unable to delete user", "error");
      },
    });
  }, [props, deleteUser]);

  return <ExpandableCard icon={{
    component: Icons.Admin.User.Default,
    bgClassName: "bg-teal-500",
  }} setToggleCallback={(toggle) => toggleCardExpansion.current = toggle}
    tag={<Error error={error} />}>
    <>
      <div className="flex flex-col gap-2 grow">
        <div className={classNames(ClassNames.Text, "text-md mt-2")}>
          {props.user.FirstName} {props.user.LastName}
        </div>
        {
          roles.length > 0 &&
          <div className="flex gap-1">
              <Pills pills={roles.map(role => ({
                id: role,
                label: role,
              }))} pillsCount={1} />
          </div>
        }
      </div>
      <div className="flex">
        <EditButton onClick={() => toggleCardExpansion.current?.(true)} />
      </div>
    </>
    <>
      <div className="flex flex-col gap-1 my-4 grow">
        <div>
          <Form variables={getDefaultForm(props.user)} defaultExtraValues={getForm()} {...formProps} />
        </div>
        <strong><label className="text-xs text-neutral-600 mt-2">Roles</label></strong>
        <div className="flex items-center gap-1 flex-wrap">
          {roles.map(role => (
            <Pill id={role} label={role} handleRemove={handleRemove} />
          ))}
          <AddRoles roles={allRolesResponse.data?.AllRoles ?? []} onClick={handleAdd} />
        </div>
      </div>
      <div className="flex justify-between items-center">
        <DeleteButton name={`${props.user.FirstName} ${props.user.LastName}`} loading={deleteLoading} onClick={handleDelete} />
        <div className="flex items-center gap-2">
            <CancelButton onClick={handleCancel} />
            <UpdateButton loading={updateLoading} onClick={handleSubmit} />
        </div>
      </div>
    </>
  </ExpandableCard>
}