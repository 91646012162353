import { OperationVariables } from "@apollo/client";
import { DocumentNode } from "graphql";
import { ReactElement } from "react";
import { graphqlClient } from "../../../api/client";

export type ExecutionResultStatus = "pending" | "done" | "failed" | "missing-info";

export type IExecutionResult = {
    id: string;
    label: string;
    icon: ReactElement;
    status: ExecutionResultStatus;
    link?: string;
    tooltip?: string;
}

export abstract class Command {
    abstract isValid(mainCommand: string): boolean;

    abstract isValidSubCommand(subCommand: string): boolean;
    
    abstract hasValidParameters(parameters: string[]): boolean;

    abstract execute(subCommand: string, parameters: string[], options: Record<string, string | string[]>): AsyncIterable<IExecutionResult>;

    async query<T extends DocumentNode, V extends OperationVariables>(query: T, variables?: V) {
        try {
            const {data} = await graphqlClient.query({
                query,
                variables,
            });
            return { data, error: null, };
        } catch (error) {
            return { data: null, error }
        }
    }

    async mutate<T extends DocumentNode, V extends OperationVariables>(mutation: T, variables?: V) {
        try {
            const {data} = await graphqlClient.mutate({
                mutation,
                variables,
            });
            return { data, error: null, };
        } catch (error) {
            return { data: null, error }
        }
    }
}