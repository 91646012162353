import classNames from "classnames";
import { cloneDeep, every } from "lodash";
import { FC, useCallback, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Updater } from "use-immer";
import { ActionButton, CancelButton, DeleteButton, EditButton, SubmitButton, UpdateButton } from "../../../components/button";
import { AdjustableCard, Error, CreateCard, GraphBasedExpandableCard } from "../../../components/card";
import { Form, IFormVariable, useFormHook } from "../../../components/form";
import { IGraphCardProps } from "../../../components/graph/graph";
import { Input, Label } from "../../../components/input";
import { Pills } from "../../../components/pill";
import { GraphElements } from "../../../config/constants";
import { Icons } from "../../../config/icons";
import { InternalRoutes } from "../../../config/internal-routes";
import { EnvironmentVariable, GetEnvironmentVariableDocument, GetEnvironmentVariablesDocument, useCreateEnvironmentVariableMutation, useDeleteEnvironmentVariableMutation, useUpdateEnvironmentVariableMutation, EnvironmentVariableItem } from "../../../generated/graphql";
import { notify } from "../../../store/function";
import { IDataTransform } from "../../dashboard/context";
import { AddDashboardCard, IAddDashboardCardProps } from "../../dashboard/dashboard-card";
import { GraphCardLoader } from "../../dashboard/graph-card";
import { ClassNames } from "../../../components/classes";
import { twMerge } from "tailwind-merge";
import { IRefetch } from "../../../api/query";

export const CONFIG_ENVIRONMENT_VARIABLE_ICON = {
    component: Icons.Config.EnvironmentVariable.Default,
    bgClassName: "bg-slate-500",
}

const DEFAULT_ENVIRONMENT_VARIABLE = {
    Key: "",
    Value: "",
};

const EnvironmentVariablesField: FC<{ value: EnvironmentVariableItem[], setValue: Updater<EnvironmentVariableItem[]> }> = ({ value, setValue }) => {
    const handleAdd = useCallback(() => {
        setValue([...value, cloneDeep(DEFAULT_ENVIRONMENT_VARIABLE)]);
    }, [value, setValue]);

    const handleChange = useCallback((index: number, text: string, type: keyof Pick<EnvironmentVariableItem, "Key" | "Value">) => {
        const newValue = cloneDeep(value);
        newValue[index][type] = text;
        setValue(newValue);
    }, [value, setValue]);

    const handleRemove = useCallback((index: number) => {
        setValue(value.filter((_, i) => i !== index));
    }, [value, setValue]);

    const handlePaste = useCallback((e: React.ClipboardEvent<HTMLInputElement>) => {
        const pasteText = e.clipboardData.getData("text");
        const lines = pasteText.split("\n");
        const newVariables: EnvironmentVariableItem[] = lines.map((line) => {
            const [key, value] = line.split("=").map((part) => part.trim());
            return key && value ? { Key: key, Value: value } : null;
        }).filter(Boolean) as EnvironmentVariableItem[];

        if (newVariables.length) {
            e.preventDefault();
            setValue([...value.filter(key => key.Key.length > 0 || key.Value.length > 0), ...newVariables]);
        }
    }, [value, setValue]);

    
    const disabled = value.length === 1;

    return <div className="flex flex-col mt-2">
        <div className="flex flex-col gap-2 overflow-y-auto overflow-x-hidden h-[180px]">
            <div className="flex items-center justify-between mt-1">
                <Label label="Variables" />
                <ActionButton icon={Icons.Add} containerClassName="shadow-sm border-0 m-0 h-8 w-8" className="stroke-green-500 dark:stroke-green-600" onClick={handleAdd} />
            </div>
            {
                value.map(({ Key, Value }, index) => (
                    <div className="flex flex-row gap-1 justify-between">
                        <Input value={Key} setValue={(val) => handleChange(index, val, "Key")} placeholder="Enter key" inputProps={{
                            onPaste: handlePaste,
                        }} />
                        <Input value={Value} setValue={(val) => handleChange(index, val, "Value")} placeholder="Enter value" />
                        <ActionButton icon={Icons.Delete} containerClassName="shadow-sm border-0 h-8 w-8 m-0 self-center" className={classNames("transition-all", {
                            "stroke-red-500 dark:stroke-red-600": !disabled,
                            "stroke-red-200 dark:stroke-red-200/10": disabled,
                         })} onClick={() => handleRemove(index)} disabled={disabled} />
                    </div>
                ))
            }
        </div>
    </div>
}

const pattern: RegExp = /^[a-zA-Z_][a-zA-Z0-9_.-]*$/;
function isValidEnvKey(key: string): boolean {
    return pattern.test(key);
}

function getDefaultForm(environmentVariable?: EnvironmentVariable): IFormVariable[] {
    return [
        {
            name: "name",
            label: "Name",
            fieldType: "text",
            validate: (value: string) => value.length > 0,
            errorMessage: "Name is required",
            defaultValue: environmentVariable?.Name,
        },
        {
            name: "variables",
            label: "Variabes",
            fieldType: "custom",
            validate: (value: EnvironmentVariableItem[]) => value.length > 0 && every(value, envVar => isValidEnvKey(envVar.Key)),
            errorMessage: (value: EnvironmentVariableItem[]) => {
                if (value.length === 0) {
                    return "At least one variable is required"
                }
                for (const envVar of value) {
                    if (!isValidEnvKey(envVar.Key)) {
                        return `Invalid key ${envVar.Key}`;
                    }
                }
                return "";
            },
            defaultValue: environmentVariable?.Variables ?? [
                DEFAULT_ENVIRONMENT_VARIABLE,
            ],
            onRender(value: EnvironmentVariableItem[], setValue: Updater<EnvironmentVariableItem[]>) {
                return <EnvironmentVariablesField value={value} setValue={setValue} />
            },
        },
    ]
}

type ICreateEnvironmentVariableCardProps = {
    isEmbedded?: boolean;
    isCreating?: boolean;
    refetch: (() => void) | ((id: string) => void);
}

export const CreateEnvironmentVariableCard: FC<ICreateEnvironmentVariableCardProps> = (props) => {
    const [error, setError] = useState("");
    const [createEnvironmentVariable, { loading }] = useCreateEnvironmentVariableMutation();
    const ref = useRef<Function>();
    const [formProps, { isFormValid, getForm }] = useFormHook();

    const handleSubmit = useCallback(() => {
        const validState = isFormValid();
        if (!validState.isValid) {
            return setError(validState.errorMessage);
        }
        setError("");
        const form = getForm();
        createEnvironmentVariable({
            variables: {
                name: form.name,
                variables: form.variables,
            },
            onCompleted: (data) => {
                notify("Environment variable created successfully", "success");
                ref.current?.();
                props.refetch(data.CreateEnvironmentVariable.Id);
            },
            onError: () => {
                notify("Unable to create environmentVariable", "error");
            },
        });
    }, [isFormValid, getForm, createEnvironmentVariable, props]);

    const handleClose = useCallback(() => {
        setError("");
        ref.current?.();
    }, [ref]);

    return (
        <>
            <CreateCard isExpanded={props.isCreating || props.isEmbedded} label="Environment variable" icon={{
                component: Icons.Add,
                bgClassName: CONFIG_ENVIRONMENT_VARIABLE_ICON.bgClassName,
            }} setToggleCallback={(toggle) => ref.current = toggle}
                tag={<Error error={error} />}>
                <div className="flex flex-col gap-1 my-4 grow">
                    <Form variables={getDefaultForm()} {...formProps} />
                </div>
                <div className="flex justify-end items-center gap-2">
                    {!props.isEmbedded && <CancelButton onClick={handleClose} />}
                    <SubmitButton loading={loading} onClick={handleSubmit} />
                </div>
            </CreateCard>
        </>
    )
}

export const EnvironmentVariableIntro: FC<{ environmentVariable: EnvironmentVariable }> = (props) => {
    return <div className="flex flex-col grow mt-2">
        <div className={classNames(ClassNames.Text, "text-md")}>
            {props.environmentVariable.Name}
        </div>
        <div className="text-xs mt-2 flex flex-wrap gap-1">
            <Pills pills={props.environmentVariable.Variables.map(({ Key: key }) => ({
                id: key,
                label: key,
              }))} pillsCount={2} />
        </div>
    </div>
}

type IEnvironmentVariableCardProps = {
    isEmbedded?: boolean;
    data: EnvironmentVariable;
    refetch: IRefetch;
}

export const EnvironmentVariableCard: FC<IEnvironmentVariableCardProps> = (props) => {
    const [error, setError] = useState("");
    const [deleteEnvironmentVariable, { loading: deleteLoading }] = useDeleteEnvironmentVariableMutation();
    const [updateEnvironmentVariable, { loading: updateLoading }] = useUpdateEnvironmentVariableMutation();
    const [formProps, { isFormValid, getForm }] = useFormHook();
    const toggleCardExpansion = useRef<Function>();
    const [searchParams, ] = useSearchParams();

    const handleCancel = useCallback(() => {
        toggleCardExpansion.current?.();
        setError("");
    }, [toggleCardExpansion]);

    const handleSubmit = useCallback(() => {
        const validState = isFormValid();
        if (!validState.isValid) {
            return setError(validState.errorMessage);
        }
        const form = getForm();
        setError("");
        updateEnvironmentVariable({
            variables: {
                id: props.data.Id,
                name: form.name,
                variables: form.variables,
            },
            onCompleted: (data) => {
                notify("Environment variable updated successfully", "success");
                props.refetch(data.UpdateEnvironmentVariable.Id);
                toggleCardExpansion.current?.();
            },
            onError: () => {
                notify("Unable to update environmentVariable", "error");
            }
        });
    }, [isFormValid, getForm, updateEnvironmentVariable, props]);

    const handleDelete = useCallback(() => {
        deleteEnvironmentVariable({
            variables: {
                id: props.data.Id,
            },
            onCompleted: () => {
                notify("Environment variable deleted successfully!", "success");
                props.refetch();
            },
            onError: () => {
                notify("Unable to delete environment variable", "error");
            },
        });
    }, [props, deleteEnvironmentVariable]);


    return <GraphBasedExpandableCard id={props.data.Id} type={GraphElements.EnvironmentVariable} icon={CONFIG_ENVIRONMENT_VARIABLE_ICON} setToggleCallback={(toggle) => toggleCardExpansion.current = toggle}
        tag={<Error error={error} />} highlight={searchParams.get("id") === props.data.Id} isExpanded={props.isEmbedded}>
        <>
            <div className="flex flex-col grow">
                <div className={classNames(ClassNames.Text, "text-md mt-2")}>
                    {props.data.Name}
                </div>
            </div>
            <div className="flex">
                <EditButton onClick={() => toggleCardExpansion.current?.(true)} />
            </div>
        </>
        <>
            <div className="flex flex-col gap-1 my-4 grow">
                <Form variables={getDefaultForm(props.data)} {...formProps} />
            </div>
            <div className={twMerge(classNames("flex justify-between items-center", {
                "justify-end": props.isEmbedded,
            }))}>
                {!props.isEmbedded && <DeleteButton name={props.data.Name} loading={deleteLoading} onClick={handleDelete} />}
                <div className="flex items-center gap-2">
                    {!props.isEmbedded && <CancelButton onClick={handleCancel} />}
                    <UpdateButton loading={updateLoading} onClick={handleSubmit} />
                </div>
            </div>
        </>
    </GraphBasedExpandableCard>
}

export const EnvironmentVariableDashboardCard: FC<{ environmentVariable: EnvironmentVariable }> = (props) => {
    return <AdjustableCard showTools={true} icon={CONFIG_ENVIRONMENT_VARIABLE_ICON}>
        <EnvironmentVariableIntro environmentVariable={props.environmentVariable} />
    </AdjustableCard>
}

export const transformEnvironmentVariableData: IDataTransform<EnvironmentVariable>  = (data?: { EnvironmentVariable: EnvironmentVariable[]}) => data?.EnvironmentVariable ?? [];

export const EnvironmentVariableGraphCard: FC<IGraphCardProps<EnvironmentVariable>> = (props) => {
    return <GraphCardLoader loader={GetEnvironmentVariableDocument} transform={data => transformEnvironmentVariableData(data)[0]} {...props}>
        {(data: EnvironmentVariable) => (<EnvironmentVariableDashboardCard environmentVariable={data} />)}
    </GraphCardLoader>;
}

export const AddEnvironmentVariableDashboardCard: FC<IAddDashboardCardProps> = (props) => {
    return (<AddDashboardCard {...props} label="Environment variable"
        query={GetEnvironmentVariablesDocument}
        transform={transformEnvironmentVariableData} type={GraphElements.EnvironmentVariable}
        icon={Icons.Config.EnvironmentVariable.Default}
        link={InternalRoutes.Config.CreatEnvironmentVariable.path} />
    );
}